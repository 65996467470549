<template>
    <header>
        <div class="inner">
            <h1><router-link to="/"><img src="../assets/common/logo-p.svg" alt="어썸멘토" /></router-link></h1>
            <nav v-if="mobile==false">
                <ul>
                    <li><router-link to="/activity">활동검색</router-link></li>
                    <li><router-link to="/mentor">멘토매칭</router-link></li>
                    <li><router-link to="/ondemand">온디맨드</router-link></li>
                </ul>
            </nav>
            <div class="right">
              <ul v-if="mobile==false&&login==false">
                <li><router-link to="/auth/login">로그인</router-link></li>
                <li><router-link to="/auth/signUp">회원가입</router-link></li>
              </ul>
              <ul v-if="mobile==false&&login==true">
                <li><router-link to="" @click="logout">로그아웃</router-link></li>
                <li><router-link to="/mypage">마이페이지</router-link></li>
                <li><router-link to="/mypage/profile">프로필</router-link></li>
                <li class="gnb_chatting"><router-link to="">채팅<span>2</span></router-link></li>
              </ul>
              <div>
                <a v-if="login==true" class="btn_alarm" href="javascript:" :class="{ active: alarm, on: showAlarm }" @click="alarm=false; showAlarm=!showAlarm">
                  <img src="../assets/common/ico-24-bell.svg" alt="알람">
                  <img src="../assets/common/ico-24-bell-on.svg" alt="알람">
                </a>
                <a class="btn_search" href="javascript:" :class="{ on: showSearch }" @click="showSearch=!showSearch">
                  <img src="../assets/common/ico-24-search.svg" alt="검색">
                  <img src="../assets/common/ico-24-search-p.svg" alt="검색">
                </a>
                <a class="btn_menu" v-if="mobile" href="javascript:" @click="showMenu=!showMenu"><img src="../assets/common/ico-24-gnb.svg" alt="메뉴"></a>
              </div>
            </div>
        </div>

        <transition name="fade" mode="out-in">
          <div id="header_search" v-if="showSearch">
            <form>
              <input type="text" placeholder="검색어를 입력하세요." />
              <input type="submit" value="검색" />
            </form>
          </div>
        </transition>

          <div id="mobile_menu">
            <transition name="fade" mode="out-in">
              <div v-if="showMenu" @click="showMenu=false" class="black"></div>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <div v-if="showMenu" class="mobile_menu_inner">
                <a class="btn_close_mobile_menu" href="javascript:" @click="showMenu=false"><img src="../assets/common/ico-24-close.svg" alt="닫기"></a>
                <div class="top">
                  <router-link to="/auth/login" v-if="login==false" @click="login=true">로그인하기<img src="../assets/common/ico-22-arrow-b.svg" alt="링크"></router-link>
                  <router-link to="/mypage/index" v-if="login==true"><span><img src="../assets/data/sample-photo-thum-02.png" alt="프로필 사진"></span>마이페이지<img src="../assets/common/ico-22-arrow-b.svg" alt="링크"></router-link>
                </div>
                <div class="mid">
                  <ul>
                    <li><router-link to="/activity">활동검색</router-link></li>
                    <li><router-link to="/mentor">멘토매칭</router-link></li>
                    <li><router-link to="/ondemand">온디맨드</router-link></li>
                  </ul>
                </div>
                <div class="bottom">
                  <ul v-if="login==false">
                    <li><router-link to="/auth/signUp">회원가입</router-link></li>
                  </ul>
                  <ul v-else-if="login==true">
                    <li><router-link to="">프로필</router-link></li>
                    <li><router-link to="">채팅<span class="alarm">2</span></router-link></li>
                  </ul>
                </div>
                <p class="btn_logout" v-if="login==true"><router-link to="" @click="logout">로그아웃</router-link></p>
              </div>
            </transition>
          </div>
    </header>
</template>

<script>
var mobile=0;
function w_resize(){
    if (window.innerWidth >= 1000) {
        mobile=0;
    } else {
        mobile=1;
    }
} w_resize();

export default {
  name: "AppHeader",
  data() {
    return {
      CurrentRoute: this.$route.path,
      mobile,
      login: false,
      alarm: true,
      showSearch: false,
      showMenu: false,
      showAlarm: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.checkLogin()
      }
      /*
      handler(to, from) {
          this.CurrentRoute=to.path;
      }
      */
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$log.debug('header showSearch', this.showSearch);
  },
  /*
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  */
  methods: {
    handleResize() {
      w_resize();
      return this.mobile = mobile;
    },
    checkLogin() {
      this.showSearch = false;
      this.login = !!this.$cookies.get('isLoggedIn');
    },
    logout() {
      localStorage.removeItem('userInfo')
      this.$cookies.remove('isLoggedIn')
      this.$cookies.remove('accessToken')
      this.login = false;
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
</style>
