import { createLogger } from 'vue-logger-plugin'

//* VueLogger 설정 *//
const loggerEnv = process.env.NODE_ENV;
// const logLevel = loggerEnv === 'development' ? 'debug' : loggerEnv === 'info' ? 'debug' : loggerEnv === 'production' ? 'error' : 'info';
const logLevel = loggerEnv === 'development' ? 'debug' : loggerEnv === 'production' ? 'error' : 'info';

// create logger with options
const logger = createLogger({
  enabled: true,
  level: logLevel,
  callerInfo: true,
  // beforeHooks: [ ... ],
  // afterHooks: [ ... ]
})

export default logger
