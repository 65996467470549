const state = () => ({
  isLoggedIn: false,
  accessToken: null,
})

const getters = {
  getLoggedIn(state) {
    return state.isLoggedIn;
  },
  getAccessToken(state) {
    return state.accessToken;
  }
}

const actions = {
  setLoggedIn(context, payload) {
    context.commit('setLoggedIn', payload);
  },
  setAccessToken(context, payload) {
    context.commit('setAccessToken', payload);
  }
}

const mutations = {
  setLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  setAccessToken(state, payload) {
    state.accessToken = payload;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
