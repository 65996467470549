import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Dialog from 'primevue/dialog'
import Editor from 'primevue/editor'
import Datepicker from 'vue3-date-time-picker'
import Dropdown from 'primevue/dropdown'
import TreeSelect from 'primevue/treeselect'
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast'
import 'vue3-date-time-picker/dist/main.css'
import logger from './logger'

//
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primeicons/primeicons.css'

import '@/assets/css/reset.css';
import '@/assets/css/type.css';
// main scss build
import '@/assets/scss/main.scss'

const app = createApp(App)
app.config.globalProperties.$filters = {
    makeComma(value) {
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}
app.component('ConfirmDialog', ConfirmDialog)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Button', Button)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dialog', Dialog)
app.component('QuillEditor', Editor)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker)
app.component('DropDown', Dropdown)
app.component('TreeSelect', TreeSelect)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toast', Toast)
app.use(router)
  .use(logger)
  .use(store)
  .use(VueCookies, {expireTimes: '2h', secure: true,})
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .mount('#wrapper')
