import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from "@/views/DefaultLayout";

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/mentor',
  },
  {
    path: '/sampleList',
    name: 'sampleList',
    component: () => import('@/views/sample/SampleList')
  },
  {
    path: '/auth',
    component: DefaultLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login')
      },
      {
        path: 'password',
        name: 'password',
        component: () => import('@/views/auth/Password')
      },
      {
        path: 'signUp',
        name: 'signUp',
        component: () => import('@/views/auth/SignUp')
      },
      {
        path: 'sns',
        name: 'sns',
        component: () => import('@/views/auth/SNSSignUp')
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/views/auth/Welcome')
      },
    ],
  },
  {
    path: '/mypage',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'mypageIndex',
        component: () => import('@/views/mypage/Index')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/mypage/Profile')
      },
      {
        path: 'secession',
        name: 'secession',
        component: () => import('@/views/mypage/Secession')
      },
      {
        path: 'mentor',
        name: 'mentor',
        component: () => import('@/views/mypage/Mentor')
      },
      {
        path: 'mentorProfile',
        name: 'mentorProfile',
        component: () => import('@/views/mypage/MentorProfile')
      },
      {
        path: 'addService',
        name: 'addService',
        component: () => import('@/views/mypage/AddService')
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('@/views/mypage/Service')
      },
      {
        path: 'chatting',
        name: 'chatting',
        component: () => import('@/views/mypage/Chatting')
      },
      {
        path: 'cash',
        name: 'cash',
        component: () => import('@/views/mypage/Cash')
      },
      {
        path: 'cashList',
        name: 'cashList',
        component: () => import('@/views/mypage/CashList')
      },
      {
        path: 'noData',
        component: import('@/views/mypage/NoData')
      },
      {
        path: 'activity',
        name: 'activity',
        component: () => import('@/views/mypage/Activity')
      },
      {
        path: 'review',
        name: 'review',
        component: () => import('@/views/mypage/Review')
      },
      {
        path: 'wishlist',
        name: 'wishlist',
        component: () => import('@/views/mypage/WishList')
      },
      {
        path: 'alarm',
        name: 'alarm',
        component: () => import('@/views/mypage/Alarm')
      },
      {
        path: 'membership',
        name: 'membership',
        component: () => import('@/views/mypage/Membership')
      },
      {
        path: 'complete',
        name: 'complete',
        component: () => import('@/views/mypage/Complete')
      },
      {
        path: 'subscribe',
        name: 'subscribe',
        component: () => import('@/views/mypage/Subscribe')
      },
      {
        path: 'alarmSetting',
        name: 'alarmSetting',
        component: () => import('@/views/mypage/AlarmSetting')
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/mypage/Notice')
      },
      {
        path: 'notice/:seq',
        name: 'noticeView',
        component: () => import('@/views/mypage/NoticeView')
      },
      {
        path: 'qna',
        name: 'qna',
        component: () => import('@/views/mypage/QNA')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/mypage/Contact')
      },
    ],
  },
  {
    path: '/ondemand',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/ondemand/Index')
      },
      {
        path: 'complete',
        component: () => import('@/views/ondemand/Complete')
      },
    ]
  },
  {
    path: '/mentor',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'mentorIndex',
        component: () => import('@/views/mentor/Index')
      },
      {
        path: 'detail',
        component: () => import('@/views/mentor/Detail')
      },
      {
        path: 'search',
        component: () => import('@/views/mentor/Search')
      },
      {
        path: 'profile',
        component: () => import('@/views/mentor/Profile')
      },
      {
        path: 'chatting',
        component: () => import('@/views/mentor/Chatting')
      },
    ]
  },
  {
    path: '/activity',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/activity/Index')
      },
      {
        path: 'detail',
        component: () => import('@/views/activity/Detail')
      },
      {
        path: 'add',
        component: () => import('@/views/activity/Add')
      },
      {
        name: 'activitySearch',
        path: 'search',
        component: () => import('@/views/activity/Search')
      },
      {
        path: 'noResult',
        component: () => import('@/views/activity/NoResult')
      },

    ]
  },
  {
    path: '/membership',
    component: DefaultLayout,
    children: [{
      path: '',
      component:() => import('@/views/mypage/Membership'),
    }]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
